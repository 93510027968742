// MODULES
@import "modules/colors";
@import "modules/fonts";
@import "modules/input-types";
@import "modules/breakpoints";

// PARTIALS
@import "partials/utils";
@import "partials/base";
@import "partials/layout";
@import "partials/forms";
@import "partials/buttons";
@import "partials/tables";
@import "partials/misc-ui";

// COMPONENTS
@import "components/header";
@import "components/not-exist";
@import "components/pagination";
@import "components/token-dashboard";
@import "components/transaction-table";
@import "components/blocks-table";
@import "components/loading-panel";
@import "components/spinner";
@import "components/json-view";
@import "components/stakes-table";
@import "components/chart";
@import "components/tabs";
@import "components/smart-contract";
@import "components/read-contract";

// SECTIONS
@import "sections/home";
@import "sections/transactions";
@import "sections/account";
@import "sections/blocks";
@import "sections/stakes";
@import "../../node_modules/react-tabs/style/react-tabs.scss";


a {
  color: rgb(115, 162, 231)
}

.read-contract__output {
  color: #fff;
  font-size: 18px;
  margin-bottom: 25px;

  overflow: hidden;
  word-break: break-word;
}

.read-contract__outputs-template {
  margin-bottom: 10px;
}

.text-green {
  color: #47ff00;
}

.sjh8g {
  margin-left: 20px;
}

.sjh8g span {
  display: block;
  margin-top: 10px;
}

.tranbdees {
  padding: 10px 30px;
  border-radius: 8px;
  background: rgb(27, 27, 27);
}

.vw3n.tranbdees {
  padding: 10px;
}

.read-contract__output--unit,
.read-contract__output--response {
  font-style: normal;
  color: #fff;
}

.read-contract__outputs {
  background: #000;
  padding: 20px;
  border-radius: 10px;
}

.actions-3 {
  padding-bottom: 60px;
}


.download-section {
  h3 {
    text-align: center;
    margin-top: 5%;
  }

  ul {
    margin-top: 10%;
    list-style: none;
    display: flex;
    padding: 0px;

    li {
      margin: 10px;

      img {
        border-radius: 10px;
      }
    }

    li:hover {
      padding: 10px;
      background: #fff;
      border-radius: 10px;
      transition: background-color 0.1s ease-in;
    }
  }
}

.meta-mega-icon {
  display: flex;
  list-style: none;
  justify-content: center;

  li {
    margin: 10px;
  }
}

.home-intro {
  cursor: pointer;
  height: 100px;
}

.home-intro:hover {
  box-shadow: 0 0 50px #fff;
  // transform: translateY(-5px);
}

::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
  background: #B6FFFE;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: #B6FFFE !important;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: 00A6A6 !important;
  background-color: 00A6A6 !important;
}


.custom-btn {
  border-radius: 10px;
  bottom: 30px;
  float: right;
  z-index: 0;
  padding: 10px 12px 8px;
}

.active-nav1 {
  right: 10%;
}

.custom-btn img {
  transition: all 0.25s;
  width: 30px;
}

.custom-btn:hover {
  transform: rotate(360deg) scale(1.1);
}

.ip-input {
  margin-top: 15px;
  width: 85%;
  height: 50px;
  // border: 1px dashed gray !important;
  border-radius: 26px !important;
}

.nodeStatus {
  background: #00000082 0% 0% no-repeat padding-box;
  box-shadow: inset -3px -3px 31px rgb(233 1 249 / 49%);
  opacity: 1;

  padding: 15px 37px;
  border-radius: 33px;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 23px;
}

.nodeStatusDisclaimer {
  width: 86%;
  margin-top: 5px;
  padding-top: 27px;
  font-weight: lighter;
  color: #fff;
  font-family: 'helveticaneuethin';
}

.custom-ip-btn {
  color: white;

  width: 36%;
  border-radius: 6px;
  width: 32%;
  background: #00000082 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px rgb(205 205 205 / 84%);
  border-radius: 47px;
  opacity: 1;
  //    -webkit-backdrop-filter: blur(30px);
  padding: 15px 20px;
  font-size: 18px;
}

.ip-btn-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.text-danger {
  top: 5px;
  color: red;
}

-webkit-appearance {}

.download-right {
  padding: 10px;
  width: 50%;
}

.download-page {
  padding: 80px 0;
}

.download-page .searchbar-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: 0 auto 50px;
}

.download-page .searchbar-flex input {
  width: 100%;
  height: 55px;
  border-radius: 8px;
  padding: 0 20px;
  box-shadow: inset 0px 0px 31px #cdcdcdd6;
  background: linear-gradient(rgb(50 80 126 / 30%), rgb(23 32 87 / 80%));
  border: none;
  outline: none;
  margin-right: 10px;
  color: #fff;
}

.download-page .searchbar-flex input::placeholder {
  color: #fff;
}

.download-page .searchbar-flex select {
  background: #507ed0 0% 0% no-repeat padding-box;
  box-shadow: inset -4px -4px 31px #cdcdcdd6;
  width: 20%;
  height: 55px;
  outline: none;
  border: none;
  color: #fff;
  border-radius: 8px;
  position: relative;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}

.download-page .searchbar-flex select option {
  background-color: #000;
}

.download-page .h-100 {
  // height: 100%;
  padding: 15px 30px;
}

.download-page .download-box {
  box-shadow: inset -3px -3px 31px rgb(233 1 249 / 49%);
  box-shadow: inset -6px -6px 31px #cdcdcdd6;
  text-align: center;
  border-radius: 22px;

  padding: 30px;
  margin-bottom: 20px;
  height: 100%;
  // width: 400px;
}

.download-page .download-box .title {
  color: #fff;
  font-size: 30px;
}

.download-page .download-box .title img {
  width: 34px;
  margin-left: 14px;
}

.download-page .download-box .des {
  color: #ffffff;
  font-size: 20px;
  padding: 15px 0;
}

.download-page .download-box button {
  /* background: transparent linear-gradient(252deg, #E643F1 0%, rgba(0, 0, 0, 0.1411764706) 100%) 0% 0% no-repeat padding-box; */
  /* box-shadow: inset -3px -3px 31px #e643f1; */
  /* border-radius: 44px; */

  padding: 18px 30px;
  color: #fff;
  font-size: 20px;
  position: relative;
  outline: none;
  background: #00000082 0% 0% no-repeat padding-box;
  box-shadow: inset -3px -3px 31px rgb(233 1 249 / 49%);
  opacity: 1;
  border: none;
  border-radius: 49px;
}

.custom-modal .modal-content {
  background: transparent linear-gradient(224deg, #00a1db2b 0%, #00000024 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px #cdcdcdd6;
  border: 1px solid #696969;
  border-radius: 22px;
  opacity: 1;
  padding: 20px;
}

.custom-modal .modal-content .close-btn i {
  color: #fff;
  text-align: end;
  display: block;
  font-size: 20px;
  cursor: pointer;
}

.custom-modal .modal-content .modal-box h4 {
  color: #fff;
  font-size: 30px;
  text-align: center;
}

.custom-modal .modal-content .modal-box h4 img {
  width: 20px;
  text-align: center;
}

.custom-modal .modal-content .modal-box p {
  color: #fff;
  font-size: 24px;
  padding: 40px 0;
  text-align: center;
}

.custom-modal .modal-content .modal-box .icon-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-modal .modal-content .modal-box .icon-flex button {
  background-color: transparent;
  border: none;
  display: grid;
  color: #fff;
  font-size: 20px;
}

.custom-modal .modal-content .modal-box .icon-flex button img {
  width: 60px;
  margin: 0 auto;
  display: block;
  padding-bottom: 10px;
}

.custom-modal .modal-content .modal-box .icon-flex button span {
  font-size: 16px;
  padding-top: 5px;
  color: #c2c2c2;
}

.custom-modal .modal-content .modal-box .icon-flex button:focus {
  background: transparent linear-gradient(224deg, #e643f14a 0%, #000000 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px #cdcdcd1f;
  border-radius: 10px;
}

.download-page-network .box {
  background: transparent linear-gradient(224deg, #00a1db2b 0%, #00000024 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px #cdcdcdd6;
  text-align: center;
  border-radius: 22px;
  padding: 30px;
  margin-bottom: 20px;
  height: 100%;
}

.downloadContainer {
  padding: 0px 100px;
}

.download-page-network {
  padding: 0 25px
}

.modalButton {
  background: #00000082 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px rgb(205 205 205 / 84%);
  border-radius: 47px;
  opacity: 1;
  color: white;
  padding: 15px 20px;
  font-size: 18px;
  margin: 0 auto;
}

.modal-header {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: none;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  margin: 0 auto;
  height: 5rem;
}

.modalButton:hover {
  background: #000000 0% 0% no-repeat padding-box !important;
  box-shadow: inset -6px -6px 31px rgb(205 205 205 / 84%) !important;
}

.download-page-network .box h4 {
  color: #fff;
  padding-top: 25px;
  font-size: 28px;
}

.download-page-network .box p {
  font-size: 20px;
  color: #fff;
  padding: 10px 0;
}

.download-page-network .icon-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}

.download-page-network .icon-flex button {
  background-color: transparent;
  border: none;
  display: grid;
  color: #fff;
  font-size: 20px;
}

.download-page-network .icon-flex button img {
  width: 60px;
  margin: 0 auto;
  display: block;
  padding-bottom: 10px;
}

.download-page-network .icon-flex button span {
  font-size: 16px;
  padding-top: 5px;
  color: #c2c2c2;
}

.download-page-network .icon-flex button:focus {
  background: transparent linear-gradient(224deg, #e643f14a 0%, #000000 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px #cdcdcd1f;
  border-radius: 10px;
}

.btn-flex.allbutton {
  padding-right: 22px;
}

.download-page-network .img img {
  width: 100%;
}



.download-page-network .box p {
  font-size: 20px;
  color: #fff;
  padding: 10px 0;
}

.download-page-network .icon-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}

.download-page-network .icon-flex button {
  background-color: transparent;
  border: none;
  display: grid;
  color: #fff;
  font-size: 20px;
}

.download-page-network .icon-flex button img {
  width: 60px;
  margin: 0 auto;
  display: block;
  padding-bottom: 10px;
}

.download-page-network .icon-flex button span {
  font-size: 16px;
  padding-top: 5px;
  color: #C2C2C2;
}

.download-page-network .icon-flex button:focus {
  background: transparent linear-gradient(224deg, #e643f14a 0%, #000000 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px #cdcdcd1f;
  border-radius: 10px;
}

.download-page-network .btn-flex {
  display: flex;
  justify-content: center;
  // align-items: center;
}

.download-page-network .btn-flex button {
  background: transparent linear-gradient(252deg, #757c7e96 0%, #00000024 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset 16px 9px 12px #ffffff2b, 0px 3px 6px #00000029;
  border: 1px solid #696969;
  border-radius: 18px;
  opacity: 1;
  padding: 18px 50px;
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
}

.download-page-network .img img {
  width: 100%;
}

.navbar-expand-lg .navbar-nav .drop-men .dropdown-menu {
  padding-left: 10px;
}

.wlladd {
  font-size: 0.875rem;
  display: contents;
}




.react-tabs__tab-list li {
  list-style: none;
}

.juh6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(35, 35, 35);
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.dkaw {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.react-tabs__tab.react-tabs__tab--selected {
  background: linear-gradient(268.92deg, #246CF9 -41.05%, #CF68FF -41.03%, #3EC3DA 167.68%);
  color: rgba(21, 22, 29, 1);
}


.react-json-view {
  box-shadow: none;
}

.pushed-content.object-container {
  font-size: 16px;
}



.colju .column {
  padding-top: 0 !important;
}



.react-tabs__tab {
  border: 0;
  padding: 8px 20px;
  border-radius: 100px;
  margin-right: 5px;
  color: #fff;
  background: rgb(47, 47, 47);

  text-align: center;
}

.pando-tabs .react-tabs__tab--disabled {
  opacity: 0.7;
  color: #fff;
}

.pretty-json-container.object-container {
  text-align: left;
}

.searchiconwrapper {
  position: relative;
  width: 100%;

  svg {
    position: absolute;
    right: -1px;
    top: 0px;
    height: 48px;
    width: 50px;
    padding: 15px;
    background: #AE7DF7;
    border-radius: 0px 8px 8px 0px;
    cursor: pointer;
  }
}

.smart-contract.verified {
  .Smart.Contract.type {
    color: rgb(255 156 72) !important
  }
}

.ethverified {

  display: inline;
  vertical-align: middle;

  cursor: pointer;
  transition: 0.3s all ease-in-out;

  svg {
    color: rgb(255 156 72) !important
  }

  span {
    display: none;
    position: absolute;
    top: -12px;
    left: 12px;
    font-size: 14px;
    padding: 10px;
    background-color: #000;
    z-index: 99;
    color: #fff;
    border-radius: 10px;
  }

  &:hover {

    span {
      display: block;
    }
  }
}

.ace_content {
  padding: 10px;
}

.ace-monokai {
  background-color: #000;
  line-height: 20px;
  font-size: 14px !important;
}

.ace-monokai .ace_gutter {
  background: #000;
}

.node-status {
  .custom-btn.hom-ref {
    top: 0
  }
}

.copyNodeSummary {
  display: none;
  position: absolute;
  top: -9px;
  left: 30px;
  padding: 10px;
  background: #9e28a6 0% 0% no-repeat padding-box;
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
  z-index: 99;
}

.nodssyn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;

  &:hover {
    .copyNodeSummary {
      display: block;
    }
  }
}

.tosender {
  text-transform: none;
  margin-right: 20px;
  color: rgba(112, 164, 231, 1);
}

.mored {
  color: #00b3a8 !important;
  margin-left: 10px;
}

.dappsonpando {
  margin: 10px;
  padding: 10px;
  display: flex;

  .wrpas {
    width: 25%;
    padding: 10px;
    text-align: center;


    div {
      background: rgba(0, 0, 0, 0.5098039216) 0% 0% no-repeat padding-box;
      padding: 30px 15px;
      height: 100%;
      box-shadow: 0px 0px 3px rgb(205 205 205 / 84%);
      border-radius: 8px;

      img {
        max-height: 50px;
      }

      &:hover {
        border: 1px solid #fff;
      }

      P {
        margin: 0px;
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 25px;
      }

      a {
        color: #ccc;
        cursor: pointer;

        &:hover {
          color: #fff
        }
      }
    }
  }

}

.viewmore {
  text-align: center;
  color: #E643F1;
  display: block;
  margin-bottom: 80px;
  cursor: pointer;
}

.dasa {

  h4 {
    text-align: center;
    margin-top: 80px;
  }
}


.nodetypws {
  display: flex;
  align-items: center;
  overflow: auto;
  margin: 10px;

  .nodetypwschild {
    display: inline-flex;
    cursor: pointer;
    border: 0;
    padding: 10px 15px;
    border-radius: 6px;
    margin-right: 10px;
    background: rgba(0, 0, 0, 0.5098039216) 0% 0% no-repeat padding-box;
    box-shadow: none;
    color: #fff;
    border-radius: 11px;
    border: 1px solid #B5D2D2;
    background: #DAF1F1;
    color: #639393;

    margin-bottom: 10px;

    &:hover {
      // background: #00aca7 0% 0% no-repeat padding-box;
    }
  }

  .nodetypwschild.active {
    background: #00aca7 0% 0% no-repeat padding-box;
    color: #fff;

  }
}

.nodedetails {
  display: flex;
  margin-bottom: 15px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5098039216) 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px rgb(205 205 205 / 84%);
  border-radius: 22px;
  width: 100%;

  div {
    width: 30%;
    margin: 15px;

    span {
      display: block;
    }

    span.headingsofnode {
      font-size: 22px;
      margin-bottom: 10px;
      font-weight: 500;
    }
  }
}

// new homepage css
.rant5 {
  margin-top: 60px;
  border-radius: 8px;
  border: 0.5px solid #C6C4C4;
  background: #FFF;
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
}


.footer-link li a {
  color: rgba(255, 255, 255, 0.48);
}

.cpyy a {
  color: #fff;
}

.footer-link li a:hover,
.cpyy a:hover,
.input-field a:hover {
  color: rgb(112, 164, 231) !important;
}

.rant5 h2 {
  border-radius: 8px 8px 0px 0px;
  color: rgba(255, 255, 255, 0.48);
  ;
  font-size: 24px;
  padding: 15px;
  font-weight: 700;
  margin-bottom: 22px;
}

.all-in1 {
  padding: 20px;
}

.rant5 h3 {
  color: #AEADAD;
  font-weight: 600;
}

.rant5 p {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #B0B0B0;
}


.page-title.blocks a,
.page-title.ui7 a {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-left: 20px;
}

h2.blokss2 {
  text-align: left !important;
  margin-bottom: 0px !important;
  margin: 5px 0px 15px !important;
}


// blocks table css
.lzi8 p {
  color: rgba(255, 255, 255, 0.66);
  /* font-family: Inter; */
  font-size: 14px;
  margin: 0px;
}

.flx {
  display: flex;
}

.lzi8 {
  padding-left: 5px;
  align-self: center;
}

.valii {
  color: rgba(255, 255, 255, 0.66);
  margin-right: 5px;
}

.nob span {
  color: rgba(255, 255, 255, 0.66);
  font-size: 14px;
}

p.nob {
  margin: 0;
}

.fintr {
  background: rgba(55, 55, 55, 1);
  color: rgba(255, 255, 255, 0.66);
  text-align: center;
  padding: 10px;
  border-radius: 8px;
}

.txn-table2 tr td:last-child a {
  text-align: center;
}

.blhhy {
  margin-right: 10px;

  img {
    width: 45px;
    height: 35px;
  }
}

.m-0 {
  margin: 0 !important;
}

table tbody tr .Coinbase.type {
  color: rgba(156, 112, 220, 1);
}

table tbody tr .Pending.type {
  color: rgba(220, 112, 121, 1);
}

table tbody tr .Transfer.type {
  color: rgb(112, 220, 117);
}

table tbody tr .pending.type {
  color: rgba(220, 112, 121, 1);
}

table tbody tr .Deposit.type {
  color: rgb(87, 98, 240);
}

table tbody tr .Withdraw.type {
  color: rgb(240, 87, 87);
}

table tbody tr .Smart.type {
  color: rgb(255 156 72)
}

tr.coinbase .type p:before,
tr.transfer .type p:before,
tr.service-payment .type p:before,
tr.split-contract .type p:before,
tr.smart-contract .type p:before,
tr.release .type p:before,
tr.rametron .type p:before,
tr.slash .type p:before,
tr.deposit-stake .type p:before,
tr.withdraw-stake .type p:before,
tr.update-validators .type p:before,
.Pending.type p:before {
  content: "c";
  display: inline-block;
  z-index: 88;
  margin-right: 8px;
  font-size: 14px;
  font-weight: 600;
  padding: 1px 8px;
  color: #000;
  border-radius: 8px;
  background: #fff;
}



tr.coinbase .type p:before {
  content: "C";
  background: rgb(156, 112, 220);
}

tr.transfer .type p:before {
  content: "T";
  background: rgb(112, 220, 117);
}

tr.service-payment .type p:before {
  content: "S";
}

tr.split-contract .type p:before {
  content: "S";
}

tr.smart-contract .type p:before {
  content: "S";
  background: rgb(255 156 72)
}

tr.release .type p:before {
  content: "R";
}


tr.rametron .type p:before {
  content: "R";
}


tr.slash .type p:before {
  content: "S";
}

tr.deposit-stake .type p:before {
  content: "D";
  background: rgb(87, 98, 240);
}


tr.withdraw-stake .type p:before {
  content: "W";
  background: rgb(240, 87, 87);
}


tr.update-validators .type p:before {
  content: "U";

}

.Pending.type p:before {
  content: "-";
  font-size: 22px;
  background: rgba(220, 112, 121, 1);
}







.flxfrom {
  color: rgba(255, 255, 255, 0.66);
  padding-right: 3px;
}

// Wallet overview section


.whsts h1 {
  color: #FFF;
  font-size: 28px;
  font-weight: 600;
  text-align: left;
  margin-top: 60px;
}

.top-60 {
  margin-top: 30px;
}


.pagination .btn.active {
  color: #ffffff;
  border-radius: 8px;
  background: rgba(112, 164, 231, 1);
}

.pagination {
  margin-bottom: 10px;
}

.btn.t {
  color: #4d4d4d;
}

.btn.t:hover,
a.btn.t:hover {
  color: #4d4d4d;
}

// rametron page css
.ram12 {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  cursor: pointer;
}

.gtr51 .active {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  border-radius: 10px;
  border: 2px solid rgba(112, 164, 231, 1);
  background: rgba(112, 164, 231, 1);
  cursor: pointer;
  color: #fff !important;
}

.ram12 p,
.gtr51 .active {
  color: rgba(112, 164, 231, 1);
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.ram12 img,
.gtr51 .active img {
  border-radius: 10px;
}

.rtlist.gtr51 {
  justify-content: space-between;
  margin-bottom: -63px;
  position: relative;
  z-index: 99;
  margin-top: 0px;
  margin-right: 15px;
}

.ramotbs ul.nav.nav-pills {
  justify-content: end;
  background: #bbf6f4;
  padding: 10px;
  border-radius: 8px;
}

.ramoverr {
  border-radius: 8px 8px 0px 0px;
  background: #C3F3F3;
  padding-top: 50px;
}

.netwrokmap {
  border-radius: 0px 0px 8px 8px;
  background: #C3F3F3;
  padding: 40px;
}

.ramotbs .nav-item {
  margin: 0px 8px;
}

.ramotbs .nav-pills .nav-link {
  border-radius: 11px;
  border: 1px solid #B5D2D2;
  background: #DAF1F1;
  color: #639393;
}

.ramotbs .nav-pills .nav-link.active {
  border-radius: 11px;
  background: rgba(112, 164, 231, 1);
  font-weight: 500;
  font-size: 16px;
  color: #fff;
}

.token.dashboard .column.gty90 .detail {
  background: none;
  box-shadow: none;
  padding: 0px;
  height: auto;
  margin: 0;
}

.token.dashboard .column .fhtt .detail .title {
  color: #4E4E4E;
  font-weight: 500;
}

.token.dashboard .column .fhtt .detail .value {
  font-size: 20px;
}

.ramoverr .token.dashboard {
  padding: 0px 30px;
}

.fhtt {
  display: flex;
}

.gtdeh {
  align-self: center;
}

.fdeea {
  padding-left: 15px;
}

.fhtt img {
  padding-right: 10px;
}

// public node page css
.csde3 {
  border-radius: 8px;
  background: #C3F3F3;
  padding: 40px;
}

.csde3 table.data.block-table thead th p {
  background: none;
  box-shadow: none;
  color: #000;
  text-transform: capitalize;
  padding: 10px 5px;
}

.csde3 table.data.block-table thead th {
  background: #fff;
  text-transform: capitalize;
}

.csde3 .members,
.csde3 .uptime {
  color: #000 !important;
}

.csde3 .table-responsive {
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.content.home1 {
  margin-top: 0px !important;
  padding-top: 160px;
}

// blocks page css
.content.blocks,
.content.transactions,
.content.stakes,
.content.block-details,
.content.account,
.content.transaction-details {
  padding-top: 125px;
  margin-top: 0px !important;
}


.fdr4 {
  padding: 78px 40px;
}

.de1 a button {
  border-radius: 23px;
  border: 1px solid #FFF;
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.48);
  padding: 10px;
  margin: 10px;
  color: #fff;
  background: #ff000000;
  width: 200px;
}

.de1 a button.mant3 {
  background: linear-gradient(136deg, #FF00B8 0%, #00FF85 100%);
  border: 0px;
}



.mnet6 .forcpoy {
  display: flex;
}

.br-rel {
  color: #000;
  font-size: 20px;
  font-weight: 500;
}

// top node page css
.container12 .nav {
  justify-content: center;
  background: rgba(27, 27, 27, 1);
  padding: 60px 50px;
  border-radius: 8px;
}

.container12 .nav-pills .nav-link {
  position: relative;
  border-radius: 50%;
  background: conic-gradient(from 90deg at 50% 50%, #9D93FE -92.66deg, rgba(41, 179, 183, 0.29) 265.48deg, #9D93FE 267.34deg, rgba(41, 179, 183, 0.29) 625.48deg);
  box-shadow: 0px 0px 50px 0px rgb(175, 125, 247);
  display: block;
  padding: 34px;
  width: 230px;
  margin: 0 auto;
  height: 230px;
}




.container12 li.nav-item {
  width: calc(100% / 3);
}

.container12 li.nav-item {
  .column {
    width: 100%;
    height: 162px;
    background: #fff;
    border-radius: 50%;
    color: #000;
    align-items: center;
    display: flex;
    text-align: center;

    .detail {
      width: 100%;
      color: #000;

      .value {
        color: #000;
        text-transform: none;
        font-weight: 600;
      }

      .title {
        color: rgba(52, 51, 55, 1);
        font-size: 20px;
        font-weight: 600;
        text-transform: none;
      }
    }

  }

  .nav-link.active {
    .column {

      background: rgb(241, 208, 80);
    }
  }

}

.stakes .container12 .title {
  font-size: 16px;
  color: #8E9299;
}

.container12 .value {
  font-size: 16px;
  color: #8E9299;
}

.stakes .container12 .active .title,
.container12 .active .value,
.active .rant6 {
  color: #fff;
}

.stake-tb .address a {
  color: rgba(112, 164, 231, 1);
}



.container12 .tab-content {
  margin-top: 40px;
  position: initial !important;
}

.container12 .tab-content>.tab-pane {
  position: initial !important;
}

.container12 td.empty {
  display: none;
}



.total76 .gsrf p:first-child {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0;
}

.total76 .gsrf .ptx {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  text-align: right;
  margin: 0;
}

.total76 .gsrf {
  width: 100% !important;
}

.side-icon {
  display: none;
}

.stak-chart {
  padding: 20px;
}




.ur7 h5 {
  font-size: 16px;
  color: #000;
  text-transform: capitalize;
}

.viewmore2 {
  width: 88%;
  margin: 0 auto;
  padding-top: 10px;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(144deg);
    transform: rotate(144deg);
  }
}

@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}

.viewmore2 p {
  color: rgba(112, 164, 231, 1);
  font-size: 14px;
  text-align: right;
}

.re40 td.empty {
  display: none;
}


.footer {
  background: rgba(23, 23, 23, 1);
  padding: 50px 90px 20px;

  .linkss {
    a {
      background-color: rgba(255, 255, 255, 0.1);
      padding: 10px;
      border-radius: 8px;
      display: inline-block;
      margin-left: 10px;
    }
  }
}

.input-field a {
  color: rgba(115, 162, 231, 1);
}

.feera p {
  color: rgba(255, 255, 255, 0.48);
  font-size: 16px;
  line-height: 28px;
}

.linkss a img {
  transition: transform .2s;
  width: 25px;
  height: 25px;
  filter: invert(1);
  object-fit: contain;
}

.linkss a:hover img {
  transform: scale(1.2);
}

.fomm form {
  width: 100%;
}

.fomm .form-group {
  display: flex;
  width: 100%;
  background: #fff;
  padding: 9px;
  border-radius: 10px;
}

.footer-link li {
  color: #000;
  list-style: none;
  padding-bottom: 15px;
  font-size: 15px;
}

.footer-link ul {
  padding: 0;
}

.footer-link h4 {
  color: rgba(255, 255, 255, 0.48);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}

.footer-link p {
  color: rgba(255, 255, 255, 0.48);
  font-size: 16px;
  margin: 10px 0px;
}

.cpyy p {
  color: #fff;
  text-align: left;
  font-size: 16px;
  margin-bottom: 0px;
}

.cpyy {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.fomm button {
  border: 0;
  background: none;
}

.topsrc {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin: 0;
  margin-right: 30px;
}

.cduj98-gif {
  width: 100%;
  text-align: center;
}

.cduj98-gif img {
  width: 235px;
  mix-blend-mode: screen;
  position: absolute;
  top: -77px;
  right: 0;
}

.gif09 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchContainer.tdcg6 {
  align-items: center;
  /* text-align: CENTER; */
  justify-content: right;
  color: #fff;
  display: flex;
  width: calc(100% - 350px);
}


.top-imga {
  width: 100%;
  position: absolute;
  top: 0;
}

.top-imga img {
  width: 100%;
  height: 350px;
}

.line.chartjs-render-monitor {
  min-height: 300;
}

.content.home1 .content.stakes {
  padding: 0 !important;
}

// details page 
// block
#app-content .content.block-details .page-title {
  text-align: left;
  margin-top: 60px;
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: 600;
}

.block-details .txt-de2 {
  border-radius: 8px;
  background: rgba(27, 27, 27, 1);
  padding: 20px 10px 40px;
}

.block-details table.details tbody tr th {
  color: rgba(255, 255, 255, 0.66) !important;
  padding-bottom: 15px;
  text-transform: capitalize;
}



.block-details table.details tbody tr td {
  color: rgba(255, 255, 255, 0.93);
  border-bottom: 0px;
  padding-bottom: 5px;
}

.block-details table.details tbody tr td a {
  color: rgba(255, 255, 255, 0.93);
}

p.finaly {
  margin: 0px;
  border-radius: 8px;
  background: rgba(17, 175, 136, 1);
  padding: 4px 10px;
  font-size: 15px;
  width: fit-content;
}


.txt-de2.nh6 {
  padding: 5px 0px;
  width: 100%;
}

img.dotimgg.pr-2 {
  margin-top: -10px;
}

.content.block-details table.transactions td.hash a {
  display: inline-block;
}


.nbcd978 {
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.btn.icon.prev {
  margin-right: 10px;
}

.btn.icon.next {
  margin-left: 10px;
}

.for-mob24 {
  display: none;
}

.content.block-details h3 {
  text-align: left;
  font-size: 22px;
  font-weight: 600;
  margin: 40px auto 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// ACCOUNT DETAIL pages


.firstab.txt-de2 {
  background: rgba(27, 27, 27, 1);
  border-radius: 8px;
  margin-top: 25px;
  padding: 10px 10px 30px;
}

.txt-de2.bott7 {
  background: transparent;
}

.account table.details tbody tr th {
  color: rgba(255, 255, 255, 0.66) !important;
  text-transform: capitalize;
  padding-bottom: 0px;
  padding: 5px 15px;
}



.account table.details tbody tr td {
  color: rgba(255, 255, 255, 0.93);
  border-bottom: 0px solid #42455e;
  padding-bottom: 5px;
  padding-top: 10px;
  vertical-align: top;
}

#app-content .content.account .page-title {
  margin-bottom: 10px;
  color: rgba(255, 255, 255, 0.93);
  text-align: left;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 500;
}

.he341 h4 {
  color: #000;
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  display: inline-block;
  width: 20%;
}

.he341 h6 {
  color: #656565;
  font-size: 18px;
  font-weight: 400;
  display: inline-block;
  width: 80%;
  text-align: right;
}

.account .bott7 table.txn-table2 tr td {
  border: 0px;
}

.stakes td.arrow-container {
  color: rgba(112, 164, 231, 1) !important;
  padding: 0px 0px 7px !important;
  text-align: center;
}

.stakes table tr td.arrow-container {
  padding: 10px !important;
}

// ===============================






.faq-section .mb-0>a {
  display: block;
  position: relative;
}

.faq-section .mb-0>a:after {
  content: "View more";
  color: rgba(112, 164, 231, 1);
  position: absolute;
  right: 0;
  font-weight: 600;
}

.faq-section .mb-0>a[aria-expanded="true"]:after {
  content: "View less";
  color: rgba(112, 164, 231, 1);
  font-weight: 600;
}

.staknhkr {
  width: 88%;
  margin: 0 auto;
}

// .collapse.forheight{

// }
.forheight.collapse:not(.show) {
  display: block;

}


// ===============================
.vie333 {
  background: rgba(47, 47, 47, 1) !important;

  td {
    font-size: 18px !important;
  }
}


.content.account .mnet6 {
  margin-top: 20px;
}

.content.account .custom-btn.trans-54 {
  background: none;
  box-shadow: none;
}


.mnet6 table.data tbody td.cxcxcx {
  padding-left: 0px !important;

}

// transitions details



.ac-det.transfre4 {
  padding-left: 35px;
}

.txt-de2.tranbdee {
  padding: 10px;
  border-radius: 8px;
  background: rgb(27, 27, 27);

}

.content.transaction-details table.details tbody tr th.hrt5 {
  padding-left: 0px;
}

.blokss2 {
  display: flex;
  align-items: center;
}

.content.transaction-details table.details tbody tr th p {
  margin: 0px 9px;
}

.cdsew31 a {
  color: rgba(255, 255, 255, 0.93);
}



.linkss a.twit img {
  padding: 5px
}

.formob91 {
  display: none;
}

.formob91 .dropdown {
  float: inherit;
}

.customtansaciton {

  .dropdown-item:focus,
  .dropdown-item:hover {
    background: #333;
  }

  .nav-link {
    font-size: 14px;
    font-weight: 500;
    color: rgba(21, 22, 29, 1) !important;
    border: 1px solid;
    border-radius: 100px;
    background: linear-gradient(268.92deg, #246CF9 -41.05%, #CF68FF -41.03%, #3EC3DA 167.68%);
    text-transform: capitalize;
    padding: 10px 25px;
  }

  li a {
    color: #15161D;
    padding: 10px 15px;
    font-weight: 400;
    font-size: 14px;
    font-weight: 500;
    display: block;
    cursor: pointer;

    &.active {
      color: rgb(156, 112, 220);
      background: #333;
    }
  }
}


.stakes {
  .fornew3 {
    background: rgba(28, 28, 28, 1);
    margin-top: 70px;
    padding-bottom: 20px;

  }

  .token.dashboard .kiimcm {
    text-align: left;


    .detail .title {
      color: rgba(114, 162, 231, 1);
      font-weight: 400;
      font-size: 16px;
    }

    .detail .value {
      color: #fff;
      font-weight: 500;
    }
  }
}

// arrow button
.btn.icon.next:hover i,
a.btn.icon.next:hover i {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA4klEQVR4nO3Z0QnCQBBF0acVWIotWIhoIQFZ8N8SRLaQtGApliALyZ9fQmYezD0FuMyNq5tEAAAAAAAAwLam1h9T60eXzLvIxabWn5Kukj6STvfb+R25/i/7qIXGlV+GHw6SZodvQlgASa/lyq8sIkRvgTHsvAy/St0OoQFkGCE8gMwipASQUYS0ADKJkBpABhHSAyg5gkUAJUawCaCkCFYBlBAh8ihsiS2wxYf+o/SPYOm/wdIHodJH4dI3Q6Vvh0s/ECn9SMxx+CHyKHxxG34o/2IknNurMQAAAAAAAJQi6Qt9pplLc0aHvAAAAABJRU5ErkJggg==) !important;
}

.btn.icon.last:hover i,
a.btn.icon.last:hover i {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAABYlAAAWJQFJUiTwAAABLklEQVR4nO3YwUkEQRBG4acYgKEYghPIombg3geWgb1rBioTyBiCoWwIUtALE0BVUaX/d+tLL/W6mYVGREREREREROS/uekw77ysG/A4lt/n02Hy2vvWa6O9eVnf5mV9iNjbm3uAeVk/gFdg6xDBNYCdPPA8lvcdInjfgC/gsluXj+Aa4Hw6/ABTpwju34BuEUL+BTpFCAlAowhhAWgSITQADSKEB6B4hJQAFI6QFoCiEVIDUDBCeoBq0gOMU97GqV/ZbZjG7UiVGqDa8CYtQMXhTUqAqsOb8ACVhzehAaoPb8ICdBjeRL0KtxjeRLwKtxneeL8KtxreeN+Ap07DG+9X4SPwOZblhzcRr8IvwHuH4c1dxKbjJrQQEiDAcfdtuaT+soiIiIiIiIj8LcAv2JKhg8pJD2oAAAAASUVORK5CYII=) !important;
}

.btn.icon.prev:hover i,
a.btn.icon.prev:hover i {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA/klEQVR4nO3ZwQnCQBCF4VEbSClWIKQF70HsQwiCd1tQUoBnT4oVWIolyEDmIuptZyfM/xWQ2fdCkk0iAAAAAAAAQB27/bDc7Yej9/BZhPOt4UXkJiKNiJwOfbf1mr3wGvTLR3i1XLXr5nG/XD3mzz2G/PIlvHqJyNlrDdUugT/h20PfPb3WUaWAKOGVewGRwivXAqKFV24FRAyvXAqIGl4VLyByeFW0gOjhVbECphBeFSlgKuFV1a1wBFwCJQ+e+iZoUj8GTeqNkEm9FTapX4ZM6tdhk/qDiEn9ScxEKKHqVngM2Y6hjZax8VpD+h8jYdT6NQYAAAAAAIBUROQNJD+YuLdGDRwAAAAASUVORK5CYII=) !important;
}

.btn.icon.first:hover i,
a.btn.icon.first:hover i {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAABYlAAAWJQFJUiTwAAABOklEQVR4nO3X0QnCMBQF0Kc4gKM4gh2kqBvofyEE+q8bqHSQOoKjdAR5EKEfpf15ybviPX9KSby3sU2EiIiIiIiIiOjfrCzzNrHrRWSfPr7aUFfofa4BfsOiJna7JnbXHGPDF6DhRURX1rmJ3d16fOgCRuG36auj9UqALWAivBpE5Gk5D2QBM+GrNtRvy7ngCigZXkEVUDq8ginAI7yCKMArvHIvwDO8ci3AO7xyKwAhvHIpACW8Kl4AUnj1E6fBnIoXkO5yle76l66GPq2OolxWAFIJbn8BlBJcnwEIJbg/BL1LgHgLeJYA8xr0KgFqH+BRAtxGqHQJkDvBkiXAboVnSjhYzgN9Fpgo4dGG+mI5B/xhaFTCrQ31yXr8jfWAOaQSshyVrQu4jM75w8K1RERERERERETlicgHWeiisqCiH9AAAAAASUVORK5CYII=) !important;
}

// spiner
.spinner,
.spinner .m {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-width: 3px !important;
}

.spinner {
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  transform: translateZ(0);
  animation: SpinnerAnimation 1.1s infinite linear;
  border-radius: 50%;
  border-left-color: rgb(156, 112, 220);
  border-bottom-color: rgb(156, 112, 220);
  border-right-color: rgb(156, 112, 220);
  border-top-color: rgb(156, 112, 220);
}

.leaflet-right {
  right: 20px !important;
}

.none.icon {
  display: none !important;

}






.nav-downloadsitemss {
  padding: 2px 5px;
  display: block;
}

.remnodesasas {
  padding-left: 20px !important;
}

.tipicheadr {
  font-size: 24px;
  // margin-left: 20px;
  margin-bottom: -40px;
  margin-top: 50px;
  text-align: CENTER;
}


// contract details

.account.contract-verification .ss5dsx {
  color: #818CF8;
  font-size: 32px;
  font-weight: 900;
}

#app-content .account.contract-verification {
  background-image: url("../../public/images/imgpsh_fullsize_anim (1).png");
  margin: 0;
  background-repeat: no-repeat;
  margin-bottom: -60px;
  padding-bottom: 100px;
  width: 100%;
}

#app-content .account.contract-verification .fhg54-tb {
  width: 90%;
  margin: 50px auto 0;
}

#app-content .account.contract-verification .react-tabs__tab.react-tabs__tab--selected,
#app-content .account.contract-verification .react-tabs__tab {
  background: linear-gradient(90deg, #4776E6 0%, #8E54E9 100%);
  box-shadow: 4px 6px 61px 0px #6368E733;
  border-radius: 25px;
  color: #F1F5F9;
  font-weight: 600;
  width: 220px;
}

#app-content .account.contract-verification .react-tabs__tab:first-child {
  background: linear-gradient(90deg, #848288 0%, #B3B3B3 100%);
  box-shadow: 4px 6px 61px 0px #6368E733;
  border-radius: 25px;
  color: #F1F5F9;
  font-weight: 600;
  width: 220px;
}

#app-content .account.contract-verification .smskfn {
  text-align: center;
  margin-bottom: 50px;
}

#app-content .account.contract-verification .actions {
  display: none;
}

.icnsicn p {
  color: #818CF8;
  font-size: 24px;
  font-weight: 600;
}

.icnsicn h5 {
  color: #fff;
  font-size: 16px;

}

.sredwer3 {
  display: none !important;
}

.topsnebar {
  display: inline-block !important;
  width: calc(20% - 20px);
  padding: 20px 10px;
  border-radius: 12px;
  background: linear-gradient(95.52deg, rgba(255, 255, 255, 0.0485) 12.56%, rgba(176, 114, 255, 0.05) 99.55%);
  box-shadow: 0px 0px 3px 1px rgb(161 78 223 / 78%);
  margin: 10px;
  // border-image-source: radial-gradient(48.6% 799.61% at 50% 50%, #4776E6 0%, rgba(142, 84, 233, 0) 100%);
  text-align: center;
}

.sedondf {
  margin-top: 10px;
}

.chandand3rxs {
  color: #fff;
  margin: 10px;
  display: inline-block;
  width: calc(50% - 20px);

  .chandleft {
    display: inline-block;
    padding: 20px 10px;
    background: rgba(255, 255, 255, 0.2);
    border-top-left-radius: 10px;
    width: 40%;
    border-bottom-left-radius: 10px;
    text-align: center;
  }

  .chandright {
    display: inline-block;
    padding: 20px 10px;
    background: rgba(255, 255, 255, 0.08);
    border-top-right-radius: 10px;
    width: 60%;
    font-size: 14px;
    color: #818CF8;
    border-bottom-right-radius: 10px;
  }
}

.chanthirdsec {
  color: #fff;
  border-bottom: 1px solid #666;

  .fsl {
    width: 25%;
    display: inline-block;
    padding: 10px;
    vertical-align: top;
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #666;

    position: relative;
    margin-bottom: 30px;
    padding-bottom: 30px;
  }

  .fsl:after {
    position: absolute;
    content: "";
    width: 50%;
    border: 1px solid rgb(0, 229, 218);
    right: 5px;
    top: 52px;
  }

  .fsl:before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid rgb(0, 229, 218);
    right: 0;
    top: 48px;
  }

  p {
    color: #ccc;
    font-size: 14px;
    margin: 0px;
    // margin-bottom: 5px;
  }

  .fslfts {
    color: #818CF8;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .fslfss {
    color: #ccc;
    font-size: 14px;
    margin-bottom: 0px;
  }

  .fslfst {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin-top: 0;
  }

  .fsl.fsf:after,
  .fsl.fsf:before {
    display: none;
  }
}

.thirsread {
  padding: 30px 0px;

  .thirdfs {
    width: 100%;
    display: block;
    margin-bottom: 15px;

    h5 {
      color: #818CF8;
      font-size: 18px;
      font-weight: 600;
      margin-right: 0px;
      display: inline-block;
      width: 20%;
      vertical-align: top;
      text-align: left;
    }

    p {
      color: #ccc;
      font-size: 14px;
      margin: 0;
      vertical-align: top;
      margin-left: 15px;
      width: calc(40% - 30px);
      display: inline-block;
      text-align: left;
    }
  }

}

.account.contract-verification .contract-info,
.account.contract-verification .contract-info__cell>div:nth-child(2),
.contract-info__title--sub {
  color: #fff;
}

.account.contract-verification .contract-info {
  border-radius: 18px;
}




@media screen and (max-width: 1400px) {
  table tbody td.overflow a {
    width: 160px;
  }

  #app-content .content {
    padding: 0px 30px
  }

  .new-hede {
    padding: 0px 30px;
  }

  .explore-1 {
    padding: 20px 45px;
  }

  .footer {
    padding: 0px 30px 20px;
  }
}

.m-0 {
  margin: 0 !important;
}


@media screen and (max-width: 1200px) {
  table tbody td.overflow a {
    width: 100px;
  }

  .token.dashboard .column .detail .title {
    font-size: 14px!important;
  }
  .th-not-exist p {
    font-size: 16px;
}
  .token.dashboard .column .detail .value {
    font-size: 18px;
  }

  .stak-chart {
    padding: 0;
    margin-bottom: 15px;
  }


  #app-content .content {
    padding: 0px 15px
  }

  .new-hede {
    padding: 0px 15px;
  }

  .explore-1 {
    padding: 20px 30px;
  }

  .footer {
    padding: 0px 15px 20px;
  }

}

@media screen and (max-width: 991px) {

  .gif09 {
    display: block;
  }


  .read-contract__output {
    font-size: 14px;
  }

  .read-contract__outputs {
    padding: 10px;

  }

  table.details tbody tr th,
  table.details tbody tr td {
    border-bottom: 0 !important;
    border-top: 0 !important;
    padding: 10px 0px;
    vertical-align: top;
  }

  .sjh8g {
    margin-left: 0;
  }

  .fhg54-tb {
    margin-top: 15px;
  }

  .customtansaciton .nav-link {
    font-size: 14px;
    padding: 10px 15px;
  }

  .contract-info__raws {
    padding: 0px !important;
  }

  .content.account .stakes {
    margin-top: 15px;
  }

  .contract-info__title {
    margin: 0px;
  }

  .content.block-details h3,
  .h5ss {
    margin: 30px 0 10px;
    font-size: 24px;
  }

  .content.account .actions .title {
    position: static;
  }

  #app-content .content.block-details .page-title {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 24px;

  }

  .content.transaction-details .details-header {
    margin-top: 15px;
  }

  .tranbdees {
    padding: 10px 15px;
  }

  table.details tbody tr th,
  table.details thead tr th:first-child {
    padding-left: 0px;
  }

  .account table.details tbody tr th {
    padding: 0;
  }

  .firstab.txt-de2 {
    margin-top: 0;
  }

  .content.account .stakes .title p,
  .juh6 p {
    font-size: 16px;
  }

  .react-tabs__tab {
    min-width: auto;
    padding: 10px 15px;
  }

  .block-details table.details tbody tr th {
    padding-bottom: 10px;
    font-size: 14px;
  }

  .searchContainer.tdcg6 {
    width: 100%;
    margin-top: 10px;
    display: block;
  }

  table tbody td.overflow a {
    width: 80px;
  }

  .cpyy {
    display: block;
  }

  .cpyy p {
    margin: 0;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .feera p {
    font-size: 14px;
    line-height: 22px;
    margin: 0;
  }

  .footer-link h4,
  .footer-link p,
  .page-title.blocks a,
  .page-title.ui7 a,
  table.details tbody tr td,
  .vie333 td,
  table.details thead tr th {
    font-size: 14px !important;
  }

  .navbar-expand-lg .navbar-nav {
    align-items: start;
  }

  .getstat {
    margin-bottom: 20px;
    padding: 10px !important;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 0;
    margin-bottom: 20px;
    font-size: 14px;
  }

  .custom-row {
    display: block !important;

    .col {
      display: inline-block;
      width: 50% !important;
    }

    .urned {
      display: none !important;
    }
  }

  .pneubst {
    margin: 0 !important;
  }

  .token.dashboard .column {
    padding-top: 0 !important;
  }

  .stakes .fornew3 {
    margin-top: 10px;
  }

  .line.chartjs-render-monitor {
    min-height: 200px;
  }

  .fintr {
    padding: 5px;
  }

  .searchContainer select {
    padding: 10px;
    font-size: 14px;
    border-radius: 8px;
  }

  .token.dashboard .chart-container .title {
    padding: 10px;
    margin-bottom: 10px;
  }

  .search-input.nwe1 {
    border-radius: 8px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 60px;
  }

  .search-select {
    position: absolute;
    z-index: 99;
    top: -45px;
    right: 0;
  }

  .explore-1 {
    padding: 30px 15px;
  }

  .topsrc {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .new-hede .navbar-nav .dropdown-menu {
    display: block;
    background: transparent;
    border: none;

    .dropdown-item {
      color: #fff;

      .nav-link {
        color: #fff;

      }
    }
  }

  .navbar-light.new-hede .nav-link {
    padding: 10px;
  }

  .token.dashboard .column .detail .value,
  #app-content .content .page-title {
    font-size: 16px;
  }

  h2.blokss2 {
    margin: 5px 0px !important;
  }

  .navbar-light.new-hede .navbar-nav .active>.nav-link {
    color: #AE7DF7 !important;
  }

  .whsts h1 {
    font-size: 24px;
    margin-top: 30px;
  }

  table.data.block-table tr td.age {
    font-size: 12px;
  }

  .mnet6 {
    margin-top: 0;
  }


  .content.stakes .chart {
    margin: 15px auto;
  }

  .container12 .nav-pills .nav-link {
    padding: 10px;
    width: 100px;
    height: 100px;
  }

  .container12 li.nav-item .column {

    height: 80px;
  }

  .container12 li.nav-item .column .detail .value {
    font-size: 14px;
  }

  .container12 li.nav-item .column .detail .title {

    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .container12 .tab-content {
    margin-top: 0;
  }

  .container12 .nav {
    padding: 30px 15px;
  }

  .row.fornew3 .col-xs-6 .column .kiimcm {
    width: 50%;
    display: inline-block;
  }

  .token.dashboard .kiimcm {
    padding: 10px 0px;
  }

  .row.fornew3 .col-xs-6 .column .kiimcm:nth-child(2n) {
    padding-left: 15px;
  }

  .top-60 {
    margin-top: 15px;
  }

  .txt-de2.jh23.mt-5 {
    margin-top: 10px !important;
  }

  .totalstk34 td {
    font-size: 16px !important;
  }

  #app-content {
    padding-bottom: 15px;
  }


}