/**
 *
 * =======================================================================
     BASE SETTINGS & TYPOGRAPHY
 * =======================================================================
 *
 */

* {
  position: relative;
  box-sizing: border-box;

}

html,
body {
  height: auto;
  margin: 0;
  padding: 0;
 
}

html {
  font: {
    family: $font-body;
    weight: $weight-regular;
  }

  color: $color-font-main;
  line-height: 1.42857143;
  -webkit-font-smoothing: antialiased;
}


/**
 *
 * =======================================================================
     Headings
 * =======================================================================
 */

h1,
h2,
h3,
h4,
h5,
h6 {
  // font-family: $font-title;
  color: $color-font-title;
  font-weight: $weight-regular;
  line-height: 1.1;
  margin: 0px 0px $line-height * 0.5rem;
  letter-spacing: 0px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  /*  voodoo to enable ligatures and kerning | https://developer.mozilla.org/en-US/docs/CSS/text-rendering */
}

h1,
h2,
h3 {
  letter-spacing: 0px;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  text-align: center;
  margin-bottom: 57px;
  padding: 9px;
  font-size: 30px;
  position: relative;
}

.span {
  font-size: 48px;

}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 0.875rem;
}

h6 {
  font-size: 0.75rem;
}

h5,
h6 {
  font-weight: $weight-light;
}

.small {
  font-size: 0.75rem;
  font-weight: $weight-light;
}

.explore-1 h3 {
  font-size: 50px;
  text-align: center;
  margin-bottom: 0px;
}

.explore-1 h2 {
  font-size: 60px;
  color: #E643F1;
  padding: 0;
  margin-bottom: 20px;
}

.t1 {
  text-align: center;
  font-size: 20px;
  width: 75%;
  margin: 0 auto;
  margin-bottom: 60px;
  line-height: 30px;
}

.search-input.nwe1 {
  background: #fff;
  border-radius: 0px 8px 8px 0px;
  color: #15161D;
  font-size: 16px;
  width: 100%;
  padding: 12px;}


.search-input.nwe1::placeholder {
  color: #CCCCCC;
  font-size: 14px;
 }

.searchContainer select {
  background: #70A4E7;
  padding: 16px 17px 16px;
  font-size: 15px;
  border-radius: 8px 0px 0px 8px;
  width: 145px;
  color: #15161D;
  cursor: pointer;
  border: 0;
  font-weight: 400;
  line-height: 16px;
}
.search-select
{
  color: #15161D;
}

.search-select .bi.bi-chevron-down {
  position: absolute;
  right: 10px;
  z-index: 1;
  top: 36%;
  pointer-events: none;
}

.search-select select option {
  margin: 40px;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  }

.search-select select option[value="1"] {
  background: rgba(100, 100, 100, 0.3);
}

.vilot {
  color: rgba(255, 255, 255, 0.93);
  font-weight: 400;
}

.overview.tab-bg {
  width: 100%;
  border-radius: 5px;
  padding: 0px;
}

.overview.tab-bg.newtag {
  background: none;
  box-shadow: none;
}

table.data.block-table thead th,
table.data.txn-table2 thead th {
  font-size: 16px;
  font-weight: 500;
  padding-left: 0px;
}

table.data.block-table tr td {
  font-size: 14px;
  padding: 15px 10px;
  color: #fff;
 }


table.txn-table2 tr td {
  font-size: 14px;
  padding: 14px 10px 13px!important;}

img.hyfrg {
  padding-right: 10px;
}

.data.block-table thead tr,
table.data.txn-table2 thead tr,
.content.transactions table.data.txn-table2 thead tr {
  border-bottom: 0px solid #fff;
}


.mor-lnk a {
  color: rgba(97, 97, 97, 1) !important;
  font-size: 16px;
}

.tbal-bl2,
.tbal-ty2 {
  padding-bottom: 25px;

}

.tbal-bl2 table tbody,
.tbal-ty2 table tbody {
  background: rgba(65, 65, 65, 0.6705882353) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.content.blocks .custom-btn {
  border-radius: 100%;
  bottom: 0px;
  float: right;
  z-index: 0;
  padding: 2px 2px 0px;
  background: #00000082 0% 0% no-repeat padding-box;
  opacity: 1;
}

.blk9 {
  border-radius: 29px !important;
  opacity: 1;
  
}


.green1 {
  color: rgba(112, 164, 231, 1) !important;
}


.content.blocks .data.block-table tbody,
.content.transactions .data.txn-table2 tbody {
  background: rgb(65 65 65 / 56%) 0% 0% no-repeat padding-box;
  opacity: 1;
}


table.data tbody tr.block-txn {
  border-bottom: 0px !important;
}


.content.transaction-details h3 {
  text-align: left;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0px;
  padding-left: 30px;
}


.content.transaction-details table.details thead tr th:first-child,
.content.account table.details thead tr th:first-child {
  color: rgba(255, 255, 255, 0.66) !important;
  font-weight: 500;
  border: none;
}

.custom-btn.trans-54 {
  border-radius: 100%;
  bottom: 0px;
  float: right;
  z-index: 0;
  padding: 2px 2px 0px;
  cursor: pointer;
}

.juh6 p .custom-btn.trans-54 img {
  width: 18px;
  padding-right: 0px;
  // margin-top/: 45px;
  cursor: pointer;
}



p.ac-det {
  background: none;
  box-shadow: none;
  color: #000;
  text-align: left;
  margin: 0px;
}

.content.account .stakes {
  margin-top: 30px;
}

.content.account .stakes .title {
  text-align: center;
  background: none;
  width: 100%;
  text-align: left;
}

.content.account .stakes .title p {
  padding: 15px 0px 0;
  margin-bottom: 5px;
  font-size: 20px;
  color: #fff;
  font-weight: 500;
 }

.stake-container td.node-type {
  text-align: left;
}
.totalstk34
{
  background-color: rgb(47, 47, 47) !important;
  td{
    font-size: 18px!important;
  }
}

.stake-container table.data tbody tr,
.stake-container table.data.txn-table2 thead tr {
  border-bottom: 0px solid rgb(255, 255, 255) !important;
}

table.details.account-info tbody tr:last-child td {
  border-bottom: 0px solid #42455e;
}

table.data.txn-table2 thead tr {
  border-bottom: 0px solid #fff;
}

.stake-container td.address,
.stake-container td.status,
.stake-container td.node-type.gcp,
.stake-container td.token {
  text-align: left;
}

.token.for45 {
  float: right;
}



.juh6.react-tabs__tab--selected {
  background: no-repeat;
  color: #fff;
  font-size: 22px;
  text-transform: uppercase;
}

.juh6.react-tabs__tab--selected {
  list-style: none;
}

.fhg54-tb .react-tabs__tab-list,
.fhg54-tb table.data.txn-table2 thead tr,
.jh23 table.data.txn-table2 thead tr,
.content.stakes .data.txn-table2 thead tr {
  border-bottom: 0px solid #aaa;
}

.react-tabs__tab:focus:after {
  background: none !important;
}

.mnet6 {
  margin-top: 15px;
}

.juh6 p {
  font-size: 24px;
  text-align: left;
  width: 100%;
  color: #fff;
  font-weight: 600;
 }





img.sifr-img {
  width: 28px;
  height: 28px;
}



.custom-btn.hom-ref {
  top: 15px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
}



h3.blk-det {
  
  padding: 20px 80px;
  border-radius: 33px;
  width: fit-content;
  margin: 0 auto;
}

.stakes .title.u7u {
  padding: 15px 20px;
  font-size: 18px;
}



#app-content .content.transaction-details .page-title {
  box-shadow: none;
}

.csdcsd {
  color: #76ebff;
}

.custom-btn.cont-u8 {
  position: absolute;
  top: 5%;
  height: 38px;
  width: 38px;
  right: 0;
  border-radius: 100%;
  bottom: 0px;
  float: right;
  /* z-index: 0; */
  padding: 2px 2px 0px;
  opacity: 1;

  z-index: 1;
}

.gsrf {
  width: 100%;
  display: flex !important;

}

.gsrf p {
  width: 50%;
  font-size: 16px;
}

.gsrf p:first-child {
  text-align: left;
  letter-spacing: 1px;
}

.gsrf .ptx {
  margin-top: 0px;
}

.gsrf img {
  width: 18px;
}

.overview.tab-bg .data.block-table thead tr {
  border-bottom: 0px solid #fff;
}

.chk-rem h2 {
  padding: 15px 30px;
  border-radius: 33px;
  font-size: 22px;
  width: fit-content;
  margin: 0 auto;
}

.nod-st .row {
  opacity: 1;

  padding: 60px;
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
}

.nod-st .row .col-lg-6 {
  align-self: center;
}

.rametron-section {
  display: flex;
  justify-content: center;
}

.ip-input {
  margin-top: 15px;
  width: 85%;
  height: 50px;
  border: 1px dashed gray !important;
  border-radius: 33px !important;
}

.ip09 .ip-input {
  margin-top: 15px;
  width: 85%;
  height: 60px;
  border: 0px gray !important;
  border-radius: 33px !important;
  /* padding: 15px 10px!important; */
  color: #0a0a0a !important;
  font-size: 16px;
}

.ip09 p:first-child {
  font-size: 20px;
}

.ip09 p.dis98 {
  font-size: 16px;
  color: #AFAFAF;
  font-family: "helveticaneuethin";
  margin-top: 15px;
}

.btn.custom-ip-btn {
  margin-top: 15px;
  color: white;
  padding: 15px 50px;
  border-radius: 6px;
  border-radius: 22px;
  opacity: 1;

  text-transform: capitalize;
}

.rametron-section img {
  width: 80% !important;
}

.anounce-sec img {
  width: 60px;
}

// trim data in table

table tbody td.overflow a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  color: rgba(112, 164, 231, 1) !important;
  max-width: 200px;
}

table.data tbody td.Smart {
  color: rgba(255, 255, 255, 0.93);
}

table.data tbody td.overflow.hasgfr4 a {
  align-self: center;
}

table.data.block-table tr .height {
  color: rgba(255, 255, 255, 0.93) !important
}

.ip-btn-left .custom-ip-btn {
  width: auto;
}

// public node page css

.pub2 p {
  background: rgba(112, 164, 231, 1);
  font-size: 18px;
  text-align: center;
  letter-spacing: 1px;
  width: 100%;
  margin-bottom: 30px;
  padding: 12px;
  border-radius: 33px;
  font-weight: 500;
}

.pub2 img {
  margin-right: 9px;
  width: 32px;
  margin-top: -8px;
  display: none;
}

.content.public-node .data.block-table tbody {
  background: rgba(65, 65, 65, 0.6705882353) 0% 0% no-repeat padding-box;
  opacity: 1;

}

.no-po {
  text-align: center;
}

.no-po {
  text-align: center;
}

.no-po h3 {
  font-size: 22px;
  margin-bottom: 30px;
}



table.data tbody tr {
  padding: 10px;
}

table tr td {
  font-size: 16px;
  // padding: 15px!important;
}

.data.block-table,
.data.txn-table2,
.txn-info.details,
.details.txn-info,
.data.transactions,
.details.account-info,
.data.txn-table2 {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}

.coin-b2 {
  width: 17px;
  height: 17px;
  margin-right: 8px;
  border-radius: 100%;
}

.Norecordfound {
  background: rgba(65, 65, 65, 0.6) 0% 0% no-repeat padding-box;
  padding: 10px;
  text-align: center;
  color: #fff;
}


// Export ui css

.content.account .actions .popup-content {
  top: auto !important;
  left: 0px !important;
  right: 0;
  margin: 0 auto;
}

.content.account .actions .popup-content .popup-arrow {
  transform: rotate(229deg) !important;
  top: -6px !important;
  left: 0 !important;
  right: 0;
  margin: 0 auto;
}

// Buttons active and foucs

.custom-btn.cont-u8:not(:disabled):not(.disabled):active:focus {
  box-shadow: inset 4px 0px 11px rgb(230 67 241 / 83%);
  background-color: rgba(0, 0, 0, 0.5098039216) 0% 0% no-repeat padding-box;
  border-color: inset 4px 0px 11px rgb(230 67 241 / 83%);
}


// table data contract
.smrt54 table tbody tr:last-child td {
  background-color: #343434b5;
  padding: 20px;
}

/**
 *
 * =======================================================================
     Paragraphs and Indentation
 * =======================================================================
 */

p {
  margin: 9px 9px 10px;
  /* bottom margin for paragraphs */
  hyphens: auto;
  line-height: 1.375rem;
  // font-weight: $weight-light;
  font-size: 14px;
}

p+p {
  margin-top: 1em;
  /* no top margin on subsequent paragraphs */
}

/**
 *
 * =======================================================================
     Links
 * =======================================================================
 */

a,
a:visited {
  text-decoration: none;
  // color: $color-font-link;
  transition: all 0.2s;

  i {
    transition: all 0.2s;
  }

  &:hover {
    color: $color-font-link-hover;
  }
}

p a {
  text-decoration: none;
}

/**
 *
 * =======================================================================
     Hyphens
 * =======================================================================
 */

abbr,
acronym,
blockquote,
code,
dir,
kbd,
listing,
plaintext,
q,
samp,
tt,
var,
xmp {
  hyphens: none;
}

/**
 *
 * =======================================================================
     Selection
 * =======================================================================
 */

::selection {
  background-color: $color-select-back !important;
  color: $color-select-type !important;
  /* WebKit/Blink Browsers */
  text-shadow: none;
}

::-moz-selection {
  background-color: $color-select-back !important;
  color: $color-select-type !important;
  /* Gecko Browsers */
  text-shadow: none;
}

table.data thead th p{
  color: rgba(255, 255, 255, 0.66);
  margin-bottom: 0;
}
