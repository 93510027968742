table.data.txn-table {
  // border-collapse: separate;
  .none {
    display: none;
  }
  
 
  td.age {
    color: $color-font-light;
    white-space: nowrap;
  }

  td.block {
    color: $color-font-light;
  }
  td.token,
  th.token {
    text-align: right;
    &.left {
      text-align: left;
      padding-left: 0;
    }
  }
  td.dim a,
  td.token {
    color: $color-font-light;
  }
  tr .icon {
    &:before {
      content: "";
      display: inline-block;
      position: relative;
      top: 2px;
      width: 32px;
      height: 20px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  tr .value {
    @include breakpoint("phablet", max) {
      .currency:before {
        top: 0;
      }
    }
  }
  tr .to.icon:before {
    background-image: url("./../../../public/images/icons/stake-in@2x.png");
  }

  tr .from.icon:before {
    background-image: url("../../../public/images/icons/stake-out@2x.png");
  }

  tr .none.icon:before {
    width: 0;
    height: 0;
  }
  @include breakpoint("tablet-wide", max) {
    // table-layout: fixed;
    tr .age {
      @include hideColumn();
    }
    tr .block {
      width: 75px;
    }
    thead th,
    tbody td,
    tbody th {
      text-align: left;
      padding: 0.5rem 0.5rem;
      font-size: 0.875rem;
    }
  }
  @include breakpoint("tablet-wide", max) {
    tr .to {
      @include hideColumn();
    }
    tr .from {
      @include hideColumn();
    }
    tr .icon {
      @include hideColumn();
    }
    tr .type {
      width: 150px;
    }
  }
  @include breakpoint("phablet", max) {
    tr .type:before {
      display: none;
    }
    tr .type {
      white-space: normal;
      width: 110px;
    }
  }
 
}
