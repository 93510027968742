table.data.block-table {
  width: 100%; 
  // background: #236c77;
  // margin: 29px;
  border-radius: 5px;
  
  // color: white;
  thead tr .height:before{
    height: 1px;
    
  }
  tr .height { 
    // width: 65px; 
    white-space: nowrap;
  }
}