.content.transactions {
}

.content.transaction-details {
  .details-header {
    @include row($justify: space-between, $align: flex-end);
    padding: 10px 0;
    margin-top: 45px;
    .txn-type {
      text-align: left;
      /* font-size: 22px; */
      font-weight: 500;
      letter-spacing: 0px;
      text-transform: capitalize;
    
    }
    .btn.raw {
      margin-bottom: 0.125rem;
      color: rgba(112, 164, 231, 1);
      font-weight: $weight-regular;
      font-size: 14px;
    }
  }
  .coinbase-output {
    @include row($align: flex-start);
    border-bottom: 0px solid $color-line;
    padding: 0.5rem 0;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: none;
    }
    & > *:first-child {
      margin-right: 1rem;
      white-space: nowrap;
      min-width: 30%;
      max-width: 50%;
    }
    & > a {
      display: block;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
    .currency {
      @include breakpoint("phablet", max) {
        flex-wrap: wrap;
      }
      @include breakpoint("phone-small", max) {
        &:before {
          display: none;
        }
      }
    }
  }
}

