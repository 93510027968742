// Misc UI elements. Not a component, but reusable pieces that can be used globally

.currency {
  display: flex;
  color: rgba(241, 208, 80, 1);
  &:before {
    content: "";
    display: inline-block;
    position: relative;
    width: 23px;
    height: 24px;
    margin-right: 0.375rem;
    top: -4px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &.PTX,
  &.PTXWei {
    &:before {
      // width: 50px;
      background-image: url("../../../public/images/PTX LOGO.svg");
      // display: none;
    }
  }
  &.pando,
  &.PandoWei {
    &:before {
      // width: 50px;
      background-image: url("../../../public/images/PTX LOGO.svg");
    }
    &.left {
      float: left;
    }
  }
}

// body.show-modal {
//   overflow: hidden;
// }


.modal {
  display: block;
  position: fixed;
  top: 0;
  // bottom: 0;
  left: 0;
  // right: 0;
  // background: transparent linear-gradient(180deg, #7089a5, #5d6e81) 0 0 no-repeat padding-box;

  .modal-close {
    display: block;
    position: absolute;
    top: 1rem;
    right: 2rem;
    z-index: 100;
    width: 50px;
    height: 50px;
    background-image: url("../../../public/images/close_button@2x.png");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: rgba($color-white, 0.05);
    border-radius: 50%;
    background-color: #4d5d6d;;
  }

  .modal.title{
    margin: 0 auto;
  }
  .modal-content {
    padding: 4rem;
    height: 100%;
    overflow-y: auto;
    overflow-x: auto;
    // background-color: #4d5d6d;
    background: rgba(0,0,0,0.2);
    text-align: center;
    border-radius: 8px;
  }
  @include breakpoint("phablet", max) {
    .modal-content {
      padding: 1rem;
    }
  }
}
