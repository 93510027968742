.pando-head {
  background: #00000082 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 31px #cdcdcdd6;
  border-radius: 0px 0px 48px 48px;
  opacity: 1;
}

.pando-head li.nav-item {
  text-align: center;
  // padding: 15px;
}

.navbar-brand {
  span.vers {
    color: rgba(165, 172, 185, 1);
    font-size: 10px;
    font-weight: 600;
    position: absolute;
    top: 26px;
    right: 8px;
  }
}

.pando-head a.nav-item {
  text-align: center;
  padding: 15px;
  display: inline-block;
}

.pando-head .navbar-toggler-icon {
  width: 20px;
  height: 24px;
}

.pando-head .nav-item>a {
  text-align: center;
  padding: 15px;
  display: inline-block;
}

.nav-item p img {
  height: 23px;
}

.annonce-mnt {
  width: 90px;
  padding-left: 20px;
}

a.lijkn-an {
  position: fixed;
  right: 0;
  z-index: 10;
  width: 138px;
  text-align: center;
}

.drop-men p img {
  margin-right: 10px;
  width: 22px;
}

.drop-men p.mro img {
  width: 33px;
}

.drop-men a#navbarDropdown {
  padding-bottom: 0;
}

li.nav-item.dropdown.drop-men {
  padding-top: 10px;
}

.drop-men .dropdown-menu {
  background: rgb(0 0 0) 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 0px 31px rgb(205 205 205 / 84%);
  border-radius: 14px;
}

img.fr-m {
  width: 26px;
  height: 23px;
  display: none;
  margin: 0 auto;
}

.active img.fr-m {
  width: 26px;
  height: 23px;
  display: inherit;
}

.pando-head .active.nav-item>a {
  color: rgba(112, 164, 231, 1);
}

.active img.fr-d {
  display: none;
}

.pub78 {
  height: 25px;
}

.lijkn-an p {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  background: #333;
  color: #fff;
  background: #DD6BFF 0% 0% no-repeat padding-box;
  box-shadow: inset 6px 6px 6px #f8f8f829;
  opacity: 1;
  padding: 10px;
  border-radius: 10px;
}

.navbar-light .navbar-toggler {
  color: rgb(252 249 249 / 50%);
  border-color: rgb(251 247 247 / 94%);
  background: #fff;
}

.do-bth {
  text-align: center;
}

.test-nt {
  text-align: center;
  font-size: 14px;
  transition: 0.5s all ease-in-out
}

.test-nt a.t4r {
  position: relative;
  transition: all 500ms ease;
  color: #1d252d;
}

.test-nt a span {
  color: #1d252d;
}

// a.t4r:hover{
//   color: #fff;
// }

.ndskdms.drop-men>a img {
  margin: 0 auto
}

.ndskdms.drop-men a.active {
  color: #E643F1;
}

.do-bth .nav-search {
  width: 100%;

  .dd-button {
    width: 100%;
  }
}

.lijkn-an:hover p {
  display: block;
}

a.lijkn-an:hover,
a.lijkn-an:visited:hover {
  color: #fff;
  text-decoration: none;
}

a:hover,
a:visited:hover {
  color: rgba(112, 164, 231, 1)!important;
  text-decoration: none;
}

.pando-header {
  @include row($justify: space-between, $align: flex-start);
  // border-radius: 20px;
  width: 100%;
  margin: 0 auto 0;
  padding: 1.5rem 0 0;
  // background: transparent linear-gradient(113deg, #000000 0%, #3d929f 52%, #36737a 100%) 0% 0% no-repeat padding-box;
  background: #ffffff1c 0% 0% no-repeat padding-box;
  box-shadow: inset -6px -6px 21px rgb(205 205 205 / 75%);
  border-radius: 0px 0px 49px 49px;
  opacity: 1;
  height: 8rem;

  .nav-buttin {
    display: none;

    position: absolute;
    right: 11px;
    color: #000;
    width: 49px;
    top: 20px;
    font-size: 24px;
    // box-shadow: 0px 0px 9px 3px rgb(0 0 0 / 50%);
    background: #25c4e4;
    height: 38px;
    cursor: pointer;
    border: none;
    border-radius: 4px;

    svg {
      width: 34px;
      height: 34px;
    }
  }

  a {
    text-decoration: none;
  }

  .pando-logo {
    flex: 0 0 7.25rem;
    height: 2.5rem;
    margin-left: 15px;
    margin-top: -7px;

    //
    // @include backgroundImage($url: "/images/logo_pando-explorer@2x.svg");
    p {
      // background: url("/images/PANDOPROJECT LOGO.svg");
      background-repeat: no-repeat;
      display: inline-block;
      width: 41px;
      height: 40px;
      vertical-align: middle;
      background-size: 100% 100%;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 45px);

      i {
        font-style: normal;
        color: #25c4e4;
      }
    }
  }

  .nav {
    top: -24px;
    bottom: 26px;
    display: flex;
    flex: 1 0 auto;
    transition: 0.3s all ease-in;
    text-transform: capitalize;
    margin-top: 26px;

    p {
      text-align: center;

      img {
        height: 25px;
        margin-bottom: 5px;
      }
    }

    a.nav-item {
      // font-family: $font-title;
      font-weight: $weight-bold;
      padding: 0.65rem;
      margin-right: 0%;
      color: $color-font-main;
      font-size: 1rem;
      line-height: 1rem;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      font-size: 16px;
      line-height: 28px;
      // text-transform: capitalize;

      &:hover {
        color: #e643f1;
        // background-color: aqua;
      }
    }
  }

  .nav-search {
    display: flex;
    align-items: center;
    color: $color-font-body;
    margin-right: 15px;
    transition: 0.3s all ease-in;
    .search-select {
      // width: 7rem;
      margin: 0 10px;
      // border-radius: 4px;
      width: 154px;

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 6px solid $color-input-text;
        position: absolute;

        pointer-events: none;
        background: transparent linear-gradient(180deg, #360046 0%, #004f51 100%) 0% 0% no-repeat padding-box;
      }

      select {
        height: 32px;
        width: 100%;
        display: inline-block;
        color: white;
        border-radius: 20px;
        border: 1px solid;

        option {
          color: black;
          // background: #2a135b;
        }
      }
    }

    .search-button {
      height: 30px;
      width: 20px;
      border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      // @include linearVerticalGradient($th-blue, $th-green);

      &:hover {
        @include linearVerticalGradient($color-blue, $color-green);
      }
    }

    .svg-icon {
      width: 80%;
      height: 80%;
      display: block;
      margin: auto;
    }

    .svg-icon path,
    .svg-icon polygon,
    .svg-icon rect {
      fill: $color-white;
    }

    .svg-icon circle {
      stroke: $color-white;
      stroke-width: 1;
    }
  }

  @include breakpoint("tablet-wide", max) {
    .nav-search .search-input {
      width: 34rem;
    }

    .nav-search .search-select {
      width: 5rem;
    }

    .nav .pando-logo {
      margin-right: 3%;
    }

    .nav a.nav-item {
      margin-right: 1%;
      letter-spacing: 0;
    }
  }

  @include breakpoint("tablet", max) {
    // display: block;

    .nav-buttin {
      display: block;
    }

    .flex-spacer {
      display: none;
    }

    .nav-search {
      width: 80%;
      margin: 3rem auto 0;
      @include row($justify: center);

      .search-input {
        width: 80%;
      }

      .search-select {
        width: 7rem;
        margin: 10px 0px;
        background: transparent linear-gradient(180deg, #360046 0%, #004f51 100%) 0% 0% no-repeat padding-box;
      }
    }

    .nav,
    .nav-search {
      max-height: 0;
      overflow: hidden;
      display: block;
      background: transparent linear-gradient(180deg, #1d252d 0%, #4d5d6d 100%) 0% 0% no-repeat padding-box;

      position: absolute;
      top: 76px;
      width: 100%;
      color: #000;
      z-index: 9;
    }

    .nav-search {
      margin: 0.5rem auto 0;
      top: 244px;
    }

    .nav a.nav-item {
      display: block;
      margin-right: 0;
      // background: #000;
    }

    .active-nav {
      padding: 10px;
      max-height: 500px;
      overflow: hidden;
    }

    .active-nav.nav-search {
      padding: 10px 15px;
      border-bottom: 1px solid #000;
      padding-bottom: 10px;
      top: 23px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  @include breakpoint("phablet", max) {

    // width÷: 95%;
    .nav .pando-logo {
      margin-right: 3%;
      flex: 0 0 6rem;
    }
  }

  @include breakpoint("phone-small", max) {
    .nav a.nav-item {
      font-size: 0.8rem;
      line-height: 0.8rem;
    }
  }
}

.logo {
  bottom: 18px;
  margin-left: 30px;
  margin-right: 10px;
  margin-top: 11px;
  margin-top: 24px;
  height: 58px;
}


