

.demgraph.newdemo {
 
  h2 {
    margin-bottom: 0;
  }
}

.far4 {
  color: #fff;
}

.token.dashboard {
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  border-bottom: 1px solid rgba(35, 35, 35, 1);
  padding-bottom: 30px;
  .column {
    width: 50%;

    padding-top: 15px;


    .currency {
      margin-top: 10px;

      &::before {
        width: 24px;
        height: 24px;
      }
    }

    .detail {
      .title {
        color: rgba(255, 255, 255, 0.66);
        font-size: 16px;
      }

      .value {
        color: rgba(114, 162, 231, 1);
        font-size: 18px;
        font-weight: 700;
        .tps {
          font-size: 12px;
          align-self: flex-end;
        }
      }
    }

   


  }

  .chart-container.homechart 
  {
    margin-bottom: 45px;
  }
  // new css start here
  .kiimcm {
    padding: 10px;
  }



  .chart-container .title {
    padding: 10px 25px;
    color: #fff;
    background: #343434;
    border-radius: 8px;
    display: inline-block;
    text-transform: capitalize;
    margin-bottom: 25px;
  }

  .fornew3 .column {
    width: 100%;
  }

  
}

