.content.blocks {

}
.content.block-details {
  .button-list {
    margin-top: 0rem;
  }
  table.transactions {
    // table-layout: fixed;
    max-width: 100%;
    th.txn-type { 
      width: 5rem;
      vertical-align: top; 
      white-space: nowrap;
    }
    td.hash {
      width: 76.5%;
      vertical-align: middle;
      a {
        display: block;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis; 
      }
    }
  }
  h3 {
    margin: 3rem 0 .75rem .75rem;
    // font-family: $font-title;
    font-weight: $weight-bold;
    font-size: 22px;
    color: $color-font-main;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 25px;
    
  }
  .block-txn {
    .txn-type {
      white-space: nowrap;
    }
  }
  

}