.stake-container {
  display: flex;
  flex-direction: column;
}

.fhg54-tb
{
  margin-top: 45px;
}

.stakes {
  width: 100%;
  
  &.half {
    width: 45%;
  }
  .title {
    display: inline-block;
    font-weight: $weight-bold;
    font-size: 1rem;
 font-size: 21px;
   

  }
 
  .total {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    color: $color-font-light;
    font-size: 0.875rem;
    padding: 0.75rem;
  }
  .empty {
    height: 0px;
    padding: 0;
    display: none;
  }
  @include breakpoint("tablet-wide", max) {
    .node-type {
      width: auto;
     
      &:before {
        display: none;
      }
    }
  }
  @include breakpoint("phablet", max) {
    .half {
      width: 100%;
      margin: 30px 3px 0 3px;
    }
    .staked {
    }
  }

}
.stake-tb .token .currency {
  float: right;
  &.left {
    float: left;
  }
  
}

.mt-5 {
  margin-top: 50px;
}
