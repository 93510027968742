.chart{
    position: relative;
    width: 50%;
    margin: 10px 25%;
    cursor: pointer;
    &.line{
        width: 100%;
        /* height: 110px; */
        margin: auto;
    }
    @include breakpoint('phablet', max) {
        width: 130%;
        margin: 10px 0 0 -15%;
    }
}