
.th-not-exist{
    text-align: center;
    font-size: 16px;
    color: #e8e9f3;
    display: flex;
    justify-content: center;
    padding: 10px;
    background: #00aca7ad;
    border-radius: 8px
}
.th-not-exist p {
   
    font-size: 24px;
    font-weight: 500;
}