.content.account {
  table.details.account-txns {
    margin-top: 2rem;
    tbody th {
      width: 200px;
    }
    tbody tr td div {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }

  
  .actions {
    
    .popup-content {
      width: 330px !important;
      background: rgba(52, 52, 52, 1)!important;
      color: $color-font-light;
      padding: 15px !important;
      .popup-row {
        display: flex;
        margin-bottom: 10px;
        
        &.header {
          display: block;
          color: $color-white;
          text-align: center;
        }
        &.err-msg {
          color: $color-red;
          padding-left: 30%;
          &.disable {
            display: none;
          }
        }
        &.buttons {
          display: flex;
          justify-content: space-around;
          .popup-reset {
            width: 30%;
            margin: 10px 0 0 0;
            font-size: 0.875rem;
            font-weight: 400;
            color: #fff;
            cursor: pointer;
            border: 0px solid #fff;
            text-align: center;
            border-radius: 8px;
            background: rgba(87, 87, 87, 1);
            padding: 10px;
          }
          .popup-download {
            width: 30%;
            margin: 10px 0 0 0;
            font-size: 0.875rem;
            font-weight: 400;
            color: #fff;
            cursor: pointer;
            border: 0px solid #fff;
            text-align: center;
            border-radius: 8px;
            padding: 10px;
            background: rgba(156, 112, 220, 1)
          }
          .popup-downloading {
            width: 40%;
            margin: 10px 0 0 0;
            font-size: 0.875rem;
            font-weight: 400;
            color: #fff;
            text-align: center;
          }
        }
        .disable {
          display: none;
        }
      }
      .popup-label {
        width: 30%;
        vertical-align: baseline;
        margin: auto;
        color: #fff;
      }
      .popup-input {
        width: 70%;
      }
      .popup-arrow {
        background: transparent linear-gradient(180deg, #1d252d, #4d5d6d) 0 0 no-repeat padding-box !important;
        border-right: 1px solid $color-font-light;
        border-bottom: 1px solid $color-font-light;
        display: none;
      }
    }
    .download {
      // position: absolute;
      left: 0;
      color: $color-font-light;
      cursor: pointer;
      @include breakpoint("phablet", max) {
        @include hideColumn();
      }
    }
    .title {
      position: absolute;
      left: calc(50% - 44px);
      color: #fff;
      font-weight: 500;
      font-size: 18px;
      top: 5px;
    }
    .switch {
      @include breakpoint("phablet", max) {
        display: none;
      }
    }
    .btn.tx {
      font-size:14px;
      font-weight: 500;
      color: rgba(114, 162, 231, 1);
      &.export {
        &:before {
          content: "";
          display: inline-block;
          position: relative;
          width: 16px;
          height: 16px;
          vertical-align: middle;
          margin-right: 10px;
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url("../../../public/images/icons/Page-1.svg");
        }
      }
    }
  }
 
  table.data.txn-table,
  & > .loading-panel {
    margin-top: 0rem;
  }

  @include breakpoint("tablet", max) {
    table.details.account-txns {
      tbody tr th {
        content: "Transactions";
      }
    }
  }
}

.act.balance {
  display: flex;
  flex-direction: row;
  .currency {
    width: 50%;
    display: flex;
  }
  .currency:before {
    display: none;
  }
  .PandoWei {
    display: none;
  }
}