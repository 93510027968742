body {
  background-color: #171717;

}


#app-container {
  height: 100%;

}

#app-content {
 
  margin-top: 0px;
  width: 100%;

  .content {
    margin: 0 auto;
    padding: 0px 90px;

    @include breakpoint("tablet", max) {
      width: 100%;
      padding: 15px;
    }

    .page-title {
      // font-family: $font-title;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      width: 100%;
      color: #fff;
      
    }
  }

}

@include breakpoint("phablet", max) {
  html {
    font-size: 14px;
  }
}

.page-title blocks::before{
  content: "";
    display: block;
    width: 544px;
    height: 1px;
    background: aqua;
    left: 76px;
    top: 50%;
    position: absolute;
}
.page-title blocks::after{
  content: "";
    display: block;
    width: 544px;
    height: 1px;
    background: aqua;
    right: 76px;
    top: 50%;
    position: absolute;
}

